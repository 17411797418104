export default {
  selectedMapStyle(state, payload) {
    state.selectedOption.mapstyle.name = payload.name;
    state.selectedOption.mapstyle.mapTileApi = payload.api;
  },
  selectedMapMarker(state, payload) {
    state.selectedOption.mapMarker.name = payload.name;
    state.selectedOption.mapMarker.index = payload.index;
  },
  setFrameSize(state, payload) {
    state.selectedOption.frameSize = payload;
  },
  setImageTitle(state, payload) {
    state.selectedOption.title = payload;
  },
  setImageSubTitle(state, payload) {
    state.selectedOption.subText = payload;
  },
  setFrameColor(state, payload) {
    state.selectedOption.frameColor.color = payload;
  },
  setGiftingMessage(state, payload) {
    state.selectedOption.setGiftingMessage = payload;
  },
  setGiftWrapping(state, payload) {
    state.selectedOption.giftWrapping = payload;
  },
  setPrintingType(state, payload) {
    state.selectedOption.printingType = payload.name;
  },
  setMapDataUrl(state, payload) {
    state.map.mapDataUrl = payload;
  },
  setMapObj(state, payload) {
    state.map.map = payload;
  },
  setMapFrame(state, payload) {
    state.map.mapFrame = payload;
  },
  setCenter(state, payload) {
    state.map.center = payload;
  },
};
