<template>
  <div class="border border-ext-light-gray rounded-lg mb-8">
    <div class="bg-ext-light-gray p-5 rounded-t-lg flex items-center">
      <span class="mr-2"><slot name="icon"></slot></span>
      <slot name="title"></slot>
    </div>
    <div class="p-5">
      <slot name="body"></slot>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
