<template>
  <div>
    <div
      v-for="(option, index) in options"
      :key="index"
      class="flex items-center mb-3"
    >
      <input
        :id="option.val"
        class="inputCheck"
        type="radio"
        name="size"
        :value="option.val"
        v-model="selectedOpt"
        @change="$emit('updateSelect', selectedOpt)"
      />
      <label
        class="form-check-label inline-block text-gray-800"
        :for="option.val"
      >
        {{ option.label }}
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      default: () => [{ val: "default", label: "Default" }],
    },
  },
  data() {
    return {
      selectedOpt: "8x12",
    };
  },
};
</script>

<style></style>
