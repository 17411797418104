<template>
  <div class="px-5 mb-4">
    <div class="font-semibold">Printing type:</div>
    <div class="text-ext-gray text-xs mb-3">Know more about Printing type</div>
    <select-option
      :options="options"
      @selectedOptionHandler="updatePrintingType"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import SelectOption from "../ui/SelectOption.vue";
export default {
  components: { SelectOption },
  data() {
    return {
      options: [
        {
          id: 0,
          image: "printType/printSingleCopy.svg",
          color: "#000000",
          name: "Title",
        },
        {
          id: 1,
          image: "printType/printDigitalCopy.svg",
          color: "#ffffff",
          name: "Title",
        },
      ],
    };
  },
  methods: {
    ...mapMutations("momentMap", ["setPrintingType"]),
    ...mapActions("checkout", ["checkPrice"]),
    updatePrintingType(type) {
      this.setPrintingType(type);
      this.checkPrice("momentMap");
    },
  },
};
</script>

<style></style>
