<template>
  <div class="z-20 relative mx-8 xl:mx-0">
    <base-text type="h2" add-class="md:w-1/2">
      We are being loved by our happy customers️
    </base-text>
    <div class="mt-16">
      <carousel :items-to-show="2">
        <slide v-for="(testimonial, index) in testimonials" :key="index">
          <div class="p-6 rounded-2xl borderDashed m-3 text-left h-full">
            <base-text type="h4">
              {{ testimonial.text }}
            </base-text>
            <div class="flex items-center mt-10">
              <img src="../assets/images/user1.png" alt="" />
              <div class="ml-3">
                <base-text type="h5">{{ testimonial.name }}</base-text>
                <div class="flex -mt-3">
                  <img src="../assets/images/star.svg" alt="" />
                  <img src="../assets/images/star.svg" alt="" />
                  <img src="../assets/images/star.svg" alt="" />
                  <img src="../assets/images/star.svg" alt="" />
                  <img src="../assets/images/star.svg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide } from "vue3-carousel";
import BaseText from "../components/ui/BaseText.vue";
export default {
  components: {
    Carousel,
    Slide,
    BaseText,
  },
  data() {
    return {
      testimonials: [
        {
          name: "Adtya Kothari",
          text: "Akansha made our day memorable. She created a timeless piece of art for us of our special day..... We love the concept !! The team co-operated very well and designed this frame according to our desire.",
          image: "",
        },
        {
          name: "Payal Dodiya",
          text: "The frames are crafted with love making our day more special. Great service and quality products. I was extremely happy with my purchase. The website was self explanatory and easy to use.",
          image: "",
        },
        {
          name: "JILL GANGAR",
          text: "Hii, your gift just made my mom's birthday extra special. It was like a cherry on the top. She really loved the frame and was overwhelmed on getting it. 💃😍 Thank you so much for incorporating her journey of 50 years in a special and. memorable way. Loved it.",
          image: "",
        },
        {
          name: "CHAITANYA KUMAR",
          text: "I had ordered for my girlfriend, amazing service provided! Customised and made according to your liking and do their best to deliver close to the special date! Most definitely a lovely gift for your close and loved ones!",
          image: "",
        },
        {
          name: "Vaidehi Zawar",
          text: "It was a memorable gift…! Rare window is really amazing platform to create customised gifts for your loved ones. Do try them, and send gifts pan India. ",
          image: "",
        },
        {
          name: "Namrata Tathode",
          text: "The frame is really nice and my husband loved it. Thank you 😊",
          image: "",
        },
        {
          name: "Adhishree",
          text: "We ordered 15 custom frames for our staff as we completed 10years of journey and we were so pleased with the service and quality of the frames hemselves!",
          image: "",
        },
      ],
    };
  },
};
</script>

<style></style>
