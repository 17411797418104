<template>
  <div class="grid grid-cols-4 lg:grid-cols-6 gap-4 pt-2">
    <span
      v-for="(opt, index) in options"
      :key="index"
      class="cursor-pointer"
      @click="selectedOptions(index, opt)"
    >
      <div
        class="w-16 h-16 rounded-md relative"
        :class="selectedMarker == index ? 'selected' : ''"
      >
        <img
          v-if="selectedMarker == index"
          src="../../assets/images/checked.svg"
          alt=""
          class="absolute -right-3 -top-3 z-10"
        />
        <img
          v-if="opt.image !== ''"
          :src="require(`../../assets/images/${opt.image}`)"
          alt=""
          class="rounded-md w-full border-2 h-full object-contain"
        />
        <span
          v-else
          class="absolute inset-0 border-2 border-gray-200 rounded-md"
          :style="{ backgroundColor: opt.color }"
        ></span>
      </div>
      <div v-if="opt.name !== ''" class="text-center text-xs mt-2">
        {{ opt.name }}
      </div>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      require: true,
      default: () => [
        {
          id: 0,
          image: "",
          color: "#F7F7F7",
          name: "",
        },
      ],
    },
  },
  data() {
    return {
      selectedMarker: 0,
    };
  },
  methods: {
    selectedOptions(index, option) {
      this.selectedMarker = index;
      this.$emit("selectedOptionHandler", option);
    },
  },
};
</script>

<style></style>
