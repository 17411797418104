<template>
  <div>
    <app-header-home @showProducts="modalChoose = !modalChoose" />
    <!-- Hero Section -->
    <base-container mode="dark">
      <div class="md:flex justify-between items-center pt-20">
        <div class="flex-1 xl:pr-8 px-8 relative">
          <img
            src="../assets/images/pattern1.svg"
            alt=""
            class="absolute -top-12 -left-5"
          />
          <base-text type="h1" add-class="text-white">
            Encase the moments that matters the most
          </base-text>
          <base-text type="h4" add-class="mb-8 text-ext-light-gray">
            Remind yourself or loved ones of that special place with our
            beautifully designed custom map
          </base-text>
          <base-button @click="modalChoose = !modalChoose"
            >Create your own</base-button
          >

          <div class="flex items-center my-10 md:mt-24">
            <img
              src="../assets/images/user1.png"
              alt=""
              class="rounded-full border-2"
            />
            <img
              src="../assets/images/user2.png"
              alt=""
              class="rounded-full border-2 -ml-8"
            />
            <img
              src="../assets/images/user1.png"
              alt=""
              class="rounded-full border-2 -ml-8"
            />
            <div class="ml-3">
              <div class="flex">
                <img src="../assets/images/star.svg" alt="" />
                <img src="../assets/images/star.svg" alt="" />
                <img src="../assets/images/star.svg" alt="" />
                <img src="../assets/images/star.svg" alt="" />
                <img src="../assets/images/star.svg" alt="" />
              </div>
              <base-text type="h5" add-class="my-1 -mb-2"
                >4.8 from 500+ customers</base-text
              >
            </div>
          </div>
        </div>
        <div class="flex-1">
          <div class="relative">
            <img
              src="../assets/images/pattern7.png"
              class="absolute right-0 -top-8 w-56 h-56 block"
            />
            <img
              src="../assets/images/heroImg.png"
              class="relative w-11/12 rounded-2xl m-4"
            />
            <img
              src="../assets/images/pattern6.svg"
              class="absolute left-0 -bottom-2"
            />
          </div>
        </div>
      </div>
    </base-container>

    <img
      src="../assets/images/divider.svg"
      alt=""
      class="w-full absolute z-10"
    />

    <!-- Story map -->
    <base-container mode="light" add-class="md:b-8 md:pt-10 md:pb-16 md:pt-20">
      <div
        class="flex justify-between items-center flex-col-reverse md:flex-row-reverse"
      >
        <div class="flex-1 pl-8 xl:pr-8 px-8">
          <base-text type="h6"> Story Map </base-text>
          <base-text type="h2"> Your story is meant to be permanent </base-text>
          <base-text type="h5" add-class="mb-8">
            Think of that special story of your life and encase it with one of
            our beautifully designed custom maps
          </base-text>
          <base-button>Create your own</base-button>
        </div>
        <div class="flex-1">
          <img
            src="../assets/images/yourStory.png"
            class="relative xl:-left-12"
          />
        </div>
      </div>
    </base-container>

    <img
      src="../assets/images/dividerBottomLight.svg"
      alt=""
      class="w-full absolute z-10"
    />

    <!-- Couple map -->
    <base-container mode="" add-class="md:pb-16 md:pt-20">
      <div
        class="flex justify-between items-center flex-col-reverse md:flex-row"
      >
        <div class="flex-1 pr-8 xl:pr-8 px-8">
          <base-text type="h6"> Couple Map </base-text>
          <base-text type="h2">
            Encase the moment of your relationship
          </base-text>
          <base-text type="h5" add-class="mb-8">
            Map to celebrate and encase the most beautiful moment of your love
            life with help of our custom designed maps
          </base-text>
          <base-button>Create your own</base-button>
        </div>
        <div class="flex-1">
          <img
            src="../assets/images/moment.png"
            class="relative xl:-right-12"
          />
        </div>
      </div>
    </base-container>

    <!-- Star map -->
    <base-container mode="light" add-class="md:pb-16 md:pt-20">
      <div
        class="flex justify-between items-center flex-col-reverse md:flex-row-reverse"
      >
        <div class="flex-1 pl-8 xl:pr-8 px-8">
          <base-text type="h6"> Star map </base-text>
          <base-text type="h2">
            Aligned stars for that special moments
          </base-text>
          <base-text type="h5" add-class="mb-8">
            Get beautiful star maps showing the unique alignment of the stars in
            a place and time chosen by you.
          </base-text>
          <base-button>Create your own</base-button>
        </div>
        <div class="flex-1">
          <img
            src="../assets/images/specialMoment.png"
            class="relative xl:-left-12"
          />
        </div>
      </div>
    </base-container>

    <img
      src="../assets/images/dividerBottomLight.svg"
      alt=""
      class="w-full absolute z-10"
    />

    <!-- Moment & Journey map -->
    <base-container add-class="md:pb-16 md:pt-20">
      <div class="grid md:grid-cols-2 gap-8 mx-4 lg:mx-8">
        <div class="bg-ext-light-pink rounded-2xl p-6 md:p-12 relative">
          <base-text type="h6"> Moment Map </base-text>
          <base-text type="h3">
            Capture the journey and view it beautifully
          </base-text>
          <base-text type="h5" add-class="mb-8">
            Journey is a long way you go, why not encase it and remind your self
            of that beautiful path you covered
          </base-text>
          <img src="../assets/images/momentMap.png" alt="" class="w-full" />
          <base-button class="mx-auto -mt-6 z-10 relative"
            >Create your own</base-button
          >
          <img
            src="../assets/images/pattern3.svg"
            alt=""
            class="absolute -left-6 bottom-4"
          />
        </div>
        <div class="bg-ext-light-pink rounded-2xl p-6 md:p-12 relative">
          <base-text type="h6"> Journey Map </base-text>
          <base-text type="h3">
            Personalize that special place of your life
          </base-text>
          <base-text type="h5" add-class="mb-8">
            The place you grew up, spent college years or went on a trip, design
            your own personalised map poster
          </base-text>
          <img
            src="../assets/images/pattern2.svg"
            alt=""
            class="absolute -right-5 -mt-14"
          />
          <img src="../assets/images/journeyMap.png" alt="" class="w-full" />
          <base-button class="mx-auto -mt-6 z-10 relative"
            >Create your own</base-button
          >
        </div>
      </div>
    </base-container>

    <img
      src="../assets/images/dividerTop.svg"
      alt=""
      class="w-full relative z-10"
    />

    <!-- Testimonials -->
    <base-container mode="dark" add-class="md:pb-16 md:pt-20 relative">
      <img
        src="../assets/images/pattern2.png"
        alt=""
        class="absolute right-0 top-2"
      />
      <UserReview />
      <img
        src="../assets/images/pattern3.png"
        alt=""
        class="absolute left-0 bottom-2"
      />
    </base-container>

    <img
      src="../assets/images/divider.svg"
      alt=""
      class="w-full absolute z-10"
    />

    <!-- How it works -->
    <base-container add-class="md:pb-16 md:pt-20 mx-8 xl:mx-0">
      <base-text type="h2" add-class="text-center"> How it works </base-text>
      <base-text type="p" add-class="mb-8 text-center md:w-1/2 mx-auto">
        Think of that special story of your life and encase it with one of our
        beautifully designed custom maps
      </base-text>
      <div class="grid lg:grid-cols-3 gap-8 mt-14">
        <div>
          <img src="../assets/images/icon_customize.svg" alt="" class="mb-3" />
          <base-text type="h3"> Customize with beautiful Designs. </base-text>
          <base-text type="p" add-class="opacity-60">
            Choose the design which makes your heart skip a bit. And gift it to
            your loved ones.
          </base-text>
        </div>
        <div>
          <img src="../assets/images/icon_personal.svg" alt="" class="mb-3" />
          <base-text type="h3"> Make it more personal. </base-text>
          <base-text type="p" add-class="opacity-60">
            Add some meaningful quote or message to give it some more personal
            touch.
          </base-text>
        </div>
        <div>
          <img src="../assets/images/icon_variety.svg" alt="" class="mb-3" />
          <base-text type="h3"> Variety of formats and sizes. </base-text>
          <base-text type="p" add-class="opacity-60">
            Which one will you choose: printed poster, framed print, or instant
            digital download?
          </base-text>
        </div>
        <div></div>
      </div>
    </base-container>

    <img
      src="../assets/images/dividerTop.svg"
      alt=""
      class="w-full relative z-10"
    />

    <!-- Why choose us -->
    <base-container mode="dark" add-class="md:pb-16 md:pt-20 relative">
      <div class="flex justify-between items-center flex-col lg:flex-row">
        <div class="flex-1">
          <img
            src="../assets/images/whyChoose.png"
            class="relative xl:-left-12 mb-6 lg:mb-0"
          />
        </div>
        <div class="flex-1 mx-8 xl:mx-0">
          <base-text type="h2" add-class="mb-6"> Why choose us? </base-text>
          <div class="flex items-start mb-6">
            <img src="../assets/images/check.svg" class="mr-2" />
            <base-text type="p">
              <strong>Surprise your loved one:</strong>
              <span class="text-ext-gray"
                >Frame your first date, hang it on the wall and keep the fire
                burning for years to come!</span
              >
            </base-text>
          </div>
          <div class="flex items-start mb-6">
            <img src="../assets/images/check.svg" class="mr-2" />
            <base-text type="p">
              <strong>Tell your story:</strong>
              <span class="text-ext-gray"
                >Personalize your walls with a map that will spark conversations
                with your guests.
              </span>
            </base-text>
          </div>
          <div class="flex items-start mb-6">
            <img src="../assets/images/check.svg" class="mr-2" />
            <base-text type="p">
              <strong>Remind of home:</strong>
              <span class="text-ext-gray"
                >You can't bring your home with you but you can make your new
                space feel like home.
              </span>
            </base-text>
          </div>
          <div class="flex items-start mb-6">
            <img src="../assets/images/check.svg" class="mr-2" />
            <base-text type="p">
              <strong>Gift something meaningful:</strong>
              <span class="text-ext-gray"
                >While it's important to get a gift that is aesthetically
                pleasing, it also must carry a meaning.</span
              >
            </base-text>
          </div>
          <div class="flex flex-wrap">
            <base-button class="mr-2 my-2">Create your own</base-button>
            <base-button mode="outlineBtn" class="my-2" to="#" link
              ><img src="../assets/images/insta.svg" class="mr-2" /> We are on
              Instagram</base-button
            >
          </div>
        </div>
      </div>
    </base-container>

    <img
      src="../assets/images/divider.svg"
      alt=""
      class="w-full absolute z-10"
    />

    <!-- Our story -->
    <base-container id="ourStory" add-class="md:pb-16 md:pt-20">
      <div class="flex justify-between items-center flex-col lg:flex-row">
        <div class="flex-1">
          <img
            src="../assets/images/ourStory.png"
            class="relative xl:-left-10"
          />
        </div>
        <div class="flex-1 mx-8 xl:mx-0">
          <base-text type="h2"> Our story </base-text>
          <base-text type="p" add-class="mb-6">
            Picture it: a summer night in 2017. Marta and Rafal walked along the
            Vistula River under a starry night sky. Creativity is our middle
            name, so it came as no surprise that we both wanted to preserve this
            most perfect moment in a unique way. After a long and fruitless
            search, we decided to create our own personalized sky map for the
            night of our first date. The Strellas brand was born.
          </base-text>
          <div class="flex flex-wrap">
            <base-button class="mr-2 my-2" @click="modalChoose = !modalChoose"
              >Create your own</base-button
            >
            <base-button
              mode="outlineBtn"
              to="#"
              link
              class="border-dark my-2"
              @click="modalTeam = !modalTeam"
              >Know the team</base-button
            >
          </div>
        </div>
      </div>
    </base-container>

    <!-- Contact Form -->
    <base-container add-class="md:pb-16 md:pt-20">
      <div
        class="bg-ext-light-pink rounded-2xl p-4 md:p-8 grid lg:grid-cols-2 gap-8 mx-4 xl:mx-0 relative pb-24 md:pb-8"
      >
        <div class="relative">
          <base-text type="h2"
            >Feel free to <br />
            connect for any query!</base-text
          >
        </div>
        <div>
          <base-text type="h4" add-class="font-bold"
            >Some basic details</base-text
          >
          <div class="relative">
            <img
              src="../assets/images/pattern4.svg"
              alt=""
              class="absolute -top-14 -right-6"
            />
            <label>Name</label>
            <input
              type="text"
              class="bg-transparent border border-ext-light-gray rounded-lg p-3 w-full mb-6"
              placeholder="Enter your name"
            />

            <label>Email</label>
            <input
              type="text"
              class="bg-transparent border border-ext-light-gray rounded-lg p-3 w-full mb-6"
              placeholder="Enter your email"
            />

            <label>Phone no.</label>
            <input
              type="text"
              class="bg-transparent border border-ext-light-gray rounded-lg p-3 w-full mb-6"
              placeholder="Enter Phone no."
            />

            <label>Message</label>
            <textarea
              class="bg-transparent border border-ext-light-gray rounded-lg p-3 w-full mb-6"
              placeholder="Enter your message"
              rows="3"
            ></textarea>

            <base-button class="mr-2 w-full">Submit now</base-button>
          </div>
          <img
            src="../assets/images/pattern5.svg"
            alt=""
            class="absolute -bottom-12 -left-5"
          />
        </div>
      </div>
    </base-container>

    <app-footer />

    <!-- Choose product modal -->
    <base-modal
      :show-modal="modalChoose"
      class="text-center"
      @closeModal="modalChoose = !modalChoose"
    >
      <base-text type="h2">Choose custom map</base-text>
      <base-text type="h5" class="max-w-lg mx-auto"
        >Select you favourite map, then design and customize according to your
        needs and preference</base-text
      >
      <div class="overflow-y-auto -mx-12 lg:-mx-0 py-14">
        <div class="flex w-max mx-auto">
          <div
            v-for="(product, index) in products"
            :key="index"
            class="mx-3 w-56"
          >
            <img :src="require(`../assets/images/${product.img}`)" alt="" />
            <base-text type="h4" class="font-semibold mt-3">{{
              product.title
            }}</base-text>
            <base-button class="mx-auto" link :to="product.link"
              >Create now</base-button
            >
          </div>
        </div>
      </div>
    </base-modal>

    <!-- Our Team modal -->
    <base-modal
      :show-modal="modalTeam"
      class="text-center"
      @closeModal="modalTeam = !modalTeam"
    >
      <base-text type="h2">Meet the team</base-text>
      <div class="overflow-y-auto -mx-12 lg:-mx-0">
        <div class="md:flex justify-around w-max mx-auto">
          <div
            v-for="(member, index) in teamMembers"
            :key="index"
            class="mx-3 md:mx-10 w-72 md:w-80 py-14"
          >
            <img
              :src="require(`../assets/images/${member.img}`)"
              alt=""
              class="mx-auto"
            />
            <base-text type="h4" class="font-semibold mt-3">{{
              member.name
            }}</base-text>
            <p class="my-3">{{ member.position }}</p>
            <p class="my-3 text-ext-gray">{{ member.about }}</p>
            <base-button class="mx-auto">Create now</base-button>
          </div>
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script>
import AppHeaderHome from "../components/AppHeaderHome.vue";
import BaseContainer from "../components/ui/BaseContainer.vue";
import BaseText from "../components/ui/BaseText.vue";
import BaseButton from "../components/ui/BaseButton.vue";

import AppFooter from "../components/AppFooter.vue";
import UserReview from "../components/UserReview.vue";
import BaseModal from "../components/ui/BaseModal.vue";
export default {
  name: "HomeView",
  components: {
    AppHeaderHome,
    BaseContainer,
    BaseText,
    BaseButton,
    UserReview,
    // Pagination,
    // Navigation,
    AppFooter,
    BaseModal,
  },
  data() {
    return {
      slickSettings: {
        arrows: true,
        dots: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 2,
            },
          },
        ],
      },
      modalChoose: false,
      modalTeam: false,
      products: [
        {
          img: "product_storyMap.png",
          title: "Story Map",
          link: "#",
        },
        {
          img: "product_coupleMap.png",
          title: "Couple Map",
          link: "#",
        },
        {
          img: "product_starMap.png",
          title: "Star Map",
          link: "#",
        },
        {
          img: "product_momentMap.png",
          title: "Moment Map",
          link: "/moment-map",
        },
        {
          img: "product_journeyMap.png",
          title: "Journey Map",
          link: "#",
        },
      ],
      teamMembers: [
        {
          img: "profilePic.png",
          name: "Akshay Loya",
          position: "Co-founder",
          about:
            "We started The Rare Window to create products that bring that warm fuzzy feeling inside when you see them, and let you love the places where you spend your time in just a little bit more.",
          linkedin: "#",
        },
        {
          img: "profilePic.png",
          name: "Akshay Loya",
          position: "Co-founder",
          about:
            "We started The Rare Window to create products that bring that warm fuzzy feeling inside when you see them, and let you love the places where you spend your time in just a little bit more.",
          linkedin: "#",
        },
      ],
    };
  },
  methods: {},
};
</script>
