export default {
  getUserDetails(state) {
    return state.userDetails;
  },
  getProductDetails(state) {
    return state.productDetails;
  },
  getPrice(state) {
    return state.price;
  },
};
