<template>
  <span class="bg-white">
    <div
      ref="product"
      class="preview w-10/12 lg:w-[480px]"
      :class="'frame' + selectedOption.frameSize"
      :style="{ outlineColor: selectedOption.frameColor.color }"
    >
      <div class="h-full p-1 border-4 border-dark">
        <div class="relative flex flex-col h-full w-full border border-dark">
          <map-component
            :api="selectedOption.mapstyle.mapTileApi"
            :markerIndex="selectedOption.mapMarker.index"
            :center="getCenter"
          />
          <div
            class="textOnMap"
            :style="{ background: selectedOption.mapstyle.contentBgColor }"
          >
            <!-- <div
          class="h-8 absolute inset-x-0 -top-8"
          :style="{
            backgroundImage:
              'linear-gradient(rgba(255,255,255,0), ' +
              selectedOption.mapstyle.contentBgColor +
              ')',
          }"
        ></div> -->
            <div class="font-ext-ScriptPTbold text-3xl mb-1">
              {{ selectedOption.title }}
            </div>
            <div class="text-xs">{{ selectedOption.subText }}</div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import MapComponent from "./map/MapComponent.vue";
// import domtoimage from "dom-to-image-more";
// import { jsPDF } from "jspdf";
// import html2canvas from "html2canvas";

export default {
  components: {
    MapComponent,
  },
  data() {
    return {
      capturing: false,
      setImage: null,
      blob: null,
    };
  },
  computed: {
    ...mapGetters("momentMap", ["selectedOption", "mapDataUrl", "getCenter"]),
  },
  mounted() {
    this.setMapFrame(this.$refs.product);
  },
  methods: {
    ...mapMutations("momentMap", ["setMapFrame"]),
    // async onCapture() {
    //   const capture = this.$refs.product;
    //   this.captureImg(capture);
    // const html2canvasOptions = {
    //   useCORS: true,
    //   type: "dataURL",
    // };

    // const printCanvas = await html2canvas(capture, html2canvasOptions);
    // printCanvas
    //   .toDataURL("image/png")
    //   .replace("image/png", "image/octet-stream");

    // console.log("img: ", printCanvas);
    // },
    // async captureImg(capture) {
    //   // Landscape export, 2×4 inches
    //   const doc = new jsPDF({
    //     orientation: "portrait",
    //     unit: "px",
    //     format: [768, 1152],
    //   });
    //   await domtoimage
    //     .toPng(capture.children[1].firstChild, { quality: 1, dpi: 300 })
    //     .then((dataUrl) => {
    //       debugger;
    //       console.log(dataUrl);
    //       this.setImage = dataUrl;
    //       this.capturing = false;

    //       let img = new Image();
    //       img.crossOrigin = "";
    //       img.src = this.setImage;

    //       doc.addImage(img, "PNG", 0, 0, 768, 1152);
    //       doc.save("two-by-four.pdf");
    //       // console.log(this.setImage);
    //     })
    //     .catch((error) => {
    //       this.capturing = false;
    //       console.error("oops, something went wrong!", error);
    //     });
    // },
  },
};
</script>

<style>
.textOnMap {
  position: relative;
  z-index: 10;
  text-align: center;
  padding: 16px;
}
.preview {
  position: relative;
  margin: auto;
  background: transparent;
  outline: 12px solid transparent;
  padding: 8px;
}
.frame8x12 {
  aspect-ratio: 8 / 12;
}
.frame8x8 {
  aspect-ratio: 8 / 8;
}
</style>
