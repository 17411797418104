import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      selectedOption: {
        title: "Test",
        subText: "Subtext about your memory",
        basePrice: 200,
        frameSize: {
          size: "8x12",
          price: 100,
        },
        printingType: {
          price: 100,
          name: "",
        },
        Location: "",
        mapstyle: {
          name: "",
          mapTileApi:
            "https://api.maptiler.com/maps/b7f08d2d-3183-43b4-823e-1644f2a7c8d1/",
          contentBgColor: "#ffffff",
          contentColor: "#000000",
        },
        mapMarker: {
          name: "",
          index: "1",
        },
        frameColor: {
          color: "#000000",
          name: "Title",
        },
        giftingMessage: "",
        giftWrapping: false,
      },
      map: {
        map: null,
        mapFrame: null,
        mapDataUrl: null,
        center: {
          lon: 88.1836844,
          lat: 27.0100396,
        },
      },
    };
  },
  mutations,
  actions,
  getters,
};
