import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import CheckoutPage from "../views/CheckoutPage.vue";
import ProceedToPayment from "../views/ProceedToPayment.vue";
import StarMap from "../views/StarMap.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/star-map",
    name: "starMap",
    component: StarMap,
  },
  {
    path: "/moment-map",
    name: "momentMap",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "MomentMap" */ "../views/MomentMap.vue"),
  },
  {
    path: "/checkout",
    name: "checkout",
    component: CheckoutPage,
  },
  {
    path: "/proceed-to-payment",
    name: "ProceedToPayment",
    component: ProceedToPayment,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
