<template>
  <div
    v-if="showModal"
    class="fixed inset-0 bg-ext-yellow bg-opacity-90 z-50 flex items-center justify-center text-white p-2"
  >
    <div
      class="bg-dark p-12 rounded-2xl relative container max-h-full overflow-auto"
    >
      <img
        src="../../assets/images/patternModalTL.png"
        alt=""
        class="absolute top-0 left-0"
      />
      <img
        src="../../assets/images/patternModalBR.png"
        alt=""
        class="absolute bottom-0 right-0"
      />
      <img
        src="../../assets/images/icon_close.svg"
        alt=""
        class="absolute right-4 top-4"
        @click="$emit('closeModal')"
      />
      <div class="z-10 relative"><slot></slot></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    showModal: {
      type: Boolean,
      default: false,
      require: false,
    },
  },
};
</script>

<style></style>
