<template>
  <div class="px-5 mb-4">
    <div class="font-semibold">Frame color:</div>
    <div class="text-ext-gray text-xs mb-3">Frame size would be 6 * 6 in</div>
    <select-option :options="options" @selectedOptionHandler="updateFrame" />
  </div>
</template>

<script>
import { mapMutations, mapActions } from "vuex";
import SelectOption from "../ui/SelectOption.vue";
export default {
  components: { SelectOption },
  data() {
    return {
      options: [
        {
          id: 0,
          image: "",
          color: "#000000",
          name: "Title",
        },
        {
          id: 1,
          image: "",
          color: "#eeeeee",
          name: "Title",
        },
        {
          id: 2,
          image: "",
          color: "#833F00",
          name: "Title",
        },
      ],
    };
  },
  methods: {
    ...mapMutations("momentMap", ["setFrameColor"]),
    ...mapActions("checkout", ["checkPrice"]),
    updateFrame(selected) {
      this.setFrameColor(selected.color);
      this.checkPrice("momentMap");
    },
  },
};
</script>

<style></style>
