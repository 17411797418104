import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

export default {
  namespaced: true,
  state() {
    return {
      price: 899,
      fileLocation: "",
      productDetails: {
        productName: "",
        frameSize: "",
        frameColor: "",
        printingType: "",
        giftingMsg: "",
        giftWrap: false,
      },
      userDetails: {
        email: "",
        send_news_and_Offers: false,
        shippingDetails: {
          firstName: "",
          lastName: "",
          address: "",
          apartment: "",
          pin: "",
          city: "",
          state: "",
          phone: "",
        },
        billingDetails: {
          firstName: "",
          lastName: "",
          address: "",
          apartment: "",
          pin: "",
          city: "",
          state: "",
          phone: "",
        },
      },
    };
  },
  mutations,
  actions,
  getters,
};
