<template>
  <base-container mode="light">
    <div
      class="grid md:grid-cols-12 gap-8 pb-4 border-b border-gray-400 px-6 xl:px-0"
    >
      <div class="lg:col-span-3 col-span-4">
        <img
          src="../assets/images/logoBlack.svg"
          alt=""
          class="md:mb-24 mb-10"
        />
        <base-text type="h6" add-class="-mb-0">RECOGNISED BY:</base-text>
        <img src="../assets/images/startup.jpg" alt="" class="mb-1" />
        <p>Startup India ID: DIPP97778</p>
      </div>

      <div class="lg:col-span-4"></div>

      <div class="lg:col-span-3 col-span-4">
        <base-text type="h6">CONTACT</base-text>
        <ul>
          <li class="mb-3">
            <a href="mailto:support@rarewindow.in" class="underline"
              >support@rarewindow.in</a
            >
          </li>
          <li class="mb-3">
            <a href="mailto:help@rarewindow.in" class="underline"
              >help@rarewindow.in</a
            >
          </li>
          <li class="mb-3"><a href="#" class="underline">On Instagram</a></li>
        </ul>
      </div>

      <div class="lg:col-span-2 col-span-3">
        <base-text type="h6">OUR PRODUCTS</base-text>
        <ul>
          <li class="mb-3">Journey Map</li>
          <li class="mb-3">Design 2 Name</li>
          <li class="mb-3">Star Map</li>
          <li class="mb-3">Design 3 Name</li>
          <li class="mb-3">Design 4 Name</li>
          <li class="mb-3">All Designs</li>
        </ul>
      </div>
    </div>
    <div class="md:flex justify-between mt-5 px-6 xl:px-0">
      <p>© 2022 The Rare Window | All rights reserved</p>
      <ul class="flex">
        <li class="mr-5">
          <router-link to="#" class="underline"
            >Terms and Condition</router-link
          >
        </li>
        <li>
          <router-link to="#" class="underline">Privacy policy</router-link>
        </li>
      </ul>
    </div>
  </base-container>
</template>

<script>
import BaseContainer from "./ui/BaseContainer.vue";
import BaseText from "./ui/BaseText.vue";
export default {
  components: { BaseContainer, BaseText },
};
</script>

<style></style>
