<template>
  <div class="px-5 mb-5">
    <div class="font-semibold mb-3">Frame Size:</div>
    <radio-option-list :options="frameOptions" @updateSelect="updateSize" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import RadioOptionList from "../ui/RadioOptionList.vue";
export default {
  components: { RadioOptionList },
  data() {
    return {
      frameOptions: [
        {
          val: "8x12",
          label: '8 x 12"',
        },
        {
          val: "8x8",
          label: '8 x 8"',
        },
      ],
    };
  },
  methods: {
    ...mapMutations("momentMap", ["setFrameSize"]),
    updateSize(size) {
      this.setFrameSize(size);
    },
  },
};
</script>

<style></style>
