<template>
  <div>
    <div
      v-if="mode == 'light'"
      class="py-8 bg-ext-light-pink text-dark"
      :class="addClass"
    >
      <div class="container max-w-6xl mx-auto">
        <slot></slot>
      </div>
    </div>
    <div
      v-else-if="mode == 'dark'"
      class="py-8 bg-dark text-white"
      :class="addClass"
    >
      <div class="container max-w-6xl mx-auto">
        <slot></slot>
      </div>
    </div>
    <div v-else class="py-8 bg-white text-dark" :class="addClass">
      <div class="container max-w-6xl mx-auto">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: "",
      required: false,
    },
    addClass: {
      type: String,
      default: null,
      required: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style></style>
