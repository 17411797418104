// import axios from "axios";
// import domtoimage from "dom-to-image-more";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import { Map } from "maplibre-gl";
// import { saveAs } from "file-saver";

export default {
  async createImg(context) {
    let map = context.getters.getMap;
    const mapFrame = context.getters.getMapFrame;
    let frameWidth = 0;
    let frameHeight = 0;
    if (context.getters.selectedFrameSize == "8x12") {
      frameWidth = 768;
      frameHeight = 1152;
    } else if (context.getters.selectedFrameSize == "6x6") {
      frameWidth = 768;
      frameHeight = 768;
    }

    const doc = new jsPDF({
      orientation: "portrait",
      unit: "px",
      format: [frameWidth, frameHeight],
      // format: "a4",
    });

    const hidden = document.createElement("div");
    hidden.className = "hidden-map";
    document.body.appendChild(hidden);
    const container = document.createElement("div");
    container.style.width = frameWidth + "px";
    container.style.height = frameHeight + "px";
    hidden.appendChild(container);
    const style = map.getStyle();
    if (style && style.sources) {
      const sources = style.sources;
      Object.keys(sources).forEach((name) => {
        const src = sources[name];
        Object.keys(src).forEach((key) => {
          if (!src[key]) delete src[key];
        });
      });
    }
    const rendermap = new Map({
      container: container,
      style: style,
      center: map.getCenter(),
      zoom: map.getZoom(),
    });
    rendermap.once("idle", () => {
      const mapDataUrl = rendermap.getCanvas().toDataURL("image/png");
      // doc.addImage(mapDataUrl, "PNG", 28, 28, 967, 1450);
      doc.addImage(
        mapDataUrl,
        "PNG",
        28,
        28,
        frameWidth - 63,
        frameHeight - 94
      );

      rendermap.remove();
      hidden.parentNode?.removeChild(hidden);
      const html2canvasOptions = {
        useCORS: true,
        backgroundColor: null,
        scale: 2,
        // width: 1024,
        // height: 1536,
        allowTaint: true,
      };
      html2canvas(mapFrame, html2canvasOptions).then((printCanvas) => {
        // doc.addImage(
        //   printCanvas.toDataURL("image/png"),
        //   "PNG",
        //   0,
        //   0,
        //   1024,
        //   1536
        // );
        doc.addImage(
          printCanvas.toDataURL("image/png"),
          "PNG",
          0,
          0,
          frameWidth,
          frameHeight
        );
        doc.save("preview.pdf");
      });
    });
  },
};
