import { createStore } from "vuex";
import momentMapModule from "./products/momentMap/index.js";
import starMapModule from "./products/starMap/index.js";
import checkoutModule from "./checkout/index.js";

export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    momentMap: momentMapModule,
    starMap: starMapModule,
    checkout: checkoutModule,
  },
});
