<template>
  <div class="map-wrap">
    <!-- <span class="absolute z-20">{{ center }}</span> -->
    <div class="map" ref="mapContainer"></div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { Map, Marker } from "maplibre-gl";
// import { shallowRef, onMounted, onUnmounted, markRaw, watch } from "vue";
// import { mapMutations, mapActions, mapGetters } from "../../map-state";
// import store from "../../map-state";
// import html2canvas from "html2canvas";
// export function setMapObj(payload) {
//   store.dispatch("momentMap/setMapObj", payload);
// }
// export function setMapDataUrl(payload) {
//   store.dispatch("momentMap/setMapDataUrl", payload);
// }
export default {
  name: "MapComponent",
  props: {
    api: {
      type: String,
      required: true,
    },
    markerIndex: {
      required: true,
    },
    center: {
      type: Array,
      required: true,
    },
  },
  // setup(props) {
  //   const { getMap } = mapGetters();
  //   // const { setMapDataUrl, setMapObj } = mapMutations();
  //   const { createImg } = mapActions();
  //   const mapContainer = shallowRef(null);
  //   setMapObj(shallowRef(null));
  //   const mapMarker = shallowRef(null);
  //   let markerIcon = document.createElement("div");
  //   markerIcon.className = `marker marker${props.markerIndex}`;
  //   // markerIcon.classList.add = "marker1";
  //   const apiKey = "lOUgqb5qcG1eDUoQSBbD";
  //   const initialState = { lng: 88.1836844, lat: 27.0100396, zoom: 5 };
  //   onMounted(() => {
  //     setMapObj(
  //       markRaw(
  //         new Map({
  //           container: mapContainer.value,
  //           style: props.api + "style.json?key=" + apiKey,
  //           center: [props.center.lon, props.center.lat],
  //           zoom: initialState.zoom,
  //         })
  //       )
  //     );

  //     getMap.value.once("idle", () => {
  //       setMapDataUrl(getMap.value.getCanvas().toDataURL("image/jpeg", 0.85));
  //       // console.log(getMap.value.getCanvas().toDataURL("image/jpeg", 0.85));
  //       // console.log(map.value.getCanvas().toDataURL("image/jpeg", 0.85));
  //     });

  //     mapMarker.value = new Marker(markerIcon)
  //       .setLngLat([props.center.lon, props.center.lat])
  //       .addTo(getMap.value);
  //   }),
  //     onUnmounted(() => {
  //       getMap.value?.remove();
  //     });
  //   watch(
  //     () => getMap.value,
  //     (newVal) => {
  //       if (newVal) {
  //         console.log("map changed");
  //         setMapObj(newVal);
  //       }
  //     }
  //   );
  //   watch(
  //     () => props.api,
  //     (newVal) => {
  //       if (newVal) {
  //         debugger;
  //         console.log(newVal);
  //         getMap.value.setStyle(newVal + "style.json?key=" + apiKey);
  //       }
  //     }
  //   );
  //   watch(
  //     () => props.markerIndex,
  //     (newVal) => {
  //       if (newVal) {
  //         mapMarker.value.remove();
  //         markerIcon = document.createElement("div");
  //         markerIcon.className = `marker marker${props.markerIndex}`;
  //         console.log(markerIcon, newVal);
  //         mapMarker.value = new Marker(markerIcon)
  //           .setLngLat([props.center.lon, props.center.lat])
  //           .addTo(getMap.value);
  //       }
  //     }
  //   );
  //   watch(
  //     () => props.center,
  //     (newVal) => {
  //       if (newVal) {
  //         getMap.value.flyTo({ center: [newVal.lon, newVal.lat] });
  //         mapMarker.value
  //           .setLngLat([newVal.lon, newVal.lat])
  //           .addTo(getMap.value);
  //       }
  //     }
  //   );
  //   return {
  //     mapContainer,
  //     createImg,
  //   };
  // },
  data() {
    return {
      apiKey: "lOUgqb5qcG1eDUoQSBbD",
      initialState: { lng: 88.1836844, lat: 27.0100396, zoom: 5 },
      mapMarker: null,
      markerIcon: document.createElement("div"),
    };
  },
  computed: {
    ...mapGetters("momentMap", ["getMap"]),
  },
  mounted() {
    let map = new Map({
      container: this.$refs.mapContainer,
      style: this.api + "style.json?key=" + this.apiKey,
      center: [this.center.lon, this.center.lat],
      zoom: this.initialState.zoom,
    });
    this.setMapObj(map);
    this.markerIcon.className = `marker marker${this.markerIndex}`;
    this.mapMarker = new Marker(this.markerIcon)
      .setLngLat([this.center.lon, this.center.lat])
      .addTo(this.getMap);

    this.getMap.once("idle", () => {
      this.setMapDataUrl(this.getMap.getCanvas().toDataURL("image/jpeg", 0.85));
    });
  },
  beforeUnmount() {
    this.getMap?.remove();
  },
  methods: {
    ...mapMutations("momentMap", ["setMapDataUrl", "setMapObj"]),
  },
  watch: {
    getMap(newVal) {
      if (newVal) {
        this.setMapObj(newVal);
      }
    },
    api(newVal) {
      if (newVal) {
        console.log(newVal);
        this.getMap.setStyle(newVal + "style.json?key=" + this.apiKey);
      }
    },
    markerIndex(newVal) {
      if (newVal) {
        this.mapMarker.remove();
        this.markerIcon = document.createElement("div");
        this.markerIcon.className = `marker marker${this.markerIndex}`;
        console.log(this.markerIcon, newVal);
        this.mapMarker = new Marker(this.markerIcon)
          .setLngLat([this.center.lon, this.center.lat])
          .addTo(this.getMap);
      }
    },
    center(newVal) {
      if (newVal) {
        this.getMap.flyTo({ center: [newVal.lon, newVal.lat] });
        this.mapMarker.setLngLat([newVal.lon, newVal.lat]).addTo(this.getMap);
      }
    },
  },
};
</script>

<style scoped>
@import "maplibre-gl/dist/maplibre-gl.css";

.map-wrap {
  position: relative;
  flex-grow: 2;
}

.map {
  position: absolute;
  top: 6px;
  left: 6px;
  right: 6px;
  bottom: 6px;
}

.watermark {
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 999;
}
.marker1 {
  background: url("../../assets/images/mapMarker/CAKE.png") no-repeat center;
}
.marker2 {
  background: url("../../assets/images/mapMarker/marker2.svg") no-repeat center;
}
.marker3 {
  background: url("../../assets/images/mapMarker/marker3.svg") no-repeat center;
}
.marker4 {
  background: url("../../assets/images/mapMarker/marker4.png") no-repeat center;
}
.marker5 {
  background: url("../../assets/images/mapMarker/marker5.png") no-repeat center;
}
</style>
