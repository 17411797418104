<!-- Please remove this file from your project -->
<template>
  <div
    class="bg-white border-b border-opacity-10 border-black p-3 md:py-3 fixed inset-x-0 top-0 z-50"
  >
    <div
      class="container max-w-6xl mx-auto flex justify-between items-center h-10"
    >
      <app-logo class="w-24 md:w-auto" />
      <select class="">
        <option>Moment Map</option>
        <option>Moment Map</option>
        <option>Moment Map</option>
      </select>
    </div>
  </div>
</template>

<script>
import AppLogo from "./ui/AppLogo.vue";
export default {
  name: "AppHeaderHome",
  components: {
    AppLogo,
  },
};
</script>
