<template>
  <div class="px-5 mb-4">
    <div class="font-semibold mb-3">Map styling:</div>
    <select-option :options="options" @selectedOptionHandler="updateMap" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import SelectOption from "../ui/SelectOption.vue";
export default {
  components: { SelectOption },
  data() {
    return {
      options: [
        {
          id: 0,
          image: "mapStyling/style1.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi:
            "https://api.maptiler.com/maps/b7f08d2d-3183-43b4-823e-1644f2a7c8d1/",
          contentBgColor: "#E2D6BA",
          contentColor: "#000000",
        },
        {
          id: 1,
          image: "mapStyling/style2.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi:
            "https://api.maptiler.com/maps/767a7b0e-b4b4-40a8-9c64-95dd96dfbc7d/",
          contentBgColor: "#E2D6BA",
          contentColor: "#000000",
        },
        {
          id: 2,
          image: "mapStyling/style3.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi:
            "https://api.maptiler.com/maps/1a9b1678-8d3d-451e-bea8-3999b7653c03/",
          contentBgColor: "#E2D6BA",
          contentColor: "#000000",
        },
        {
          id: 3,
          image: "mapStyling/style4.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi:
            "https://api.maptiler.com/maps/72b160ef-7141-407b-9733-49656876a2e9/",
          contentBgColor: "#E2D6BA",
          contentColor: "#000000",
        },
        {
          id: 4,
          image: "mapStyling/style5.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi:
            "https://api.maptiler.com/maps/b7f08d2d-3183-43b4-823e-1644f2a7c8d1/",
          contentBgColor: "#E2D6BA",
          contentColor: "#000000",
        },
        {
          id: 5,
          image: "mapStyling/style6.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi: "",
        },
        {
          id: 6,
          image: "mapStyling/style7.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi: "",
        },
        {
          id: 7,
          image: "mapStyling/style8.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi: "",
        },
        {
          id: 8,
          image: "mapStyling/style9.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi: "",
        },
        {
          id: 9,
          image: "mapStyling/style10.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi: "",
        },
        {
          id: 10,
          image: "mapStyling/style11.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi: "",
        },
        {
          id: 11,
          image: "mapStyling/style12.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi: "",
        },
        {
          id: 12,
          image: "mapStyling/style13.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi: "",
        },
        {
          id: 13,
          image: "mapStyling/style14.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi: "",
        },
        {
          id: 14,
          image: "mapStyling/style15.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi: "",
        },
        {
          id: 15,
          image: "mapStyling/style16.svg",
          color: "#F7F7F7",
          name: "",
          mapTileApi: "",
        },
      ],
    };
  },
  methods: {
    ...mapMutations("momentMap", ["selectedMapStyle"]),
    updateMap(selected) {
      // console.log(selected.mapTileApi);
      this.selectedMapStyle({
        name: selected.name,
        api: selected.mapTileApi,
      });
    },
  },
};
</script>

<style></style>
