<template>
  <div class="px-5 mb-4">
    <div class="font-semibold mb-3">Map marker:</div>
    <select-option :options="options" @selectedOptionHandler="updateMap" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import SelectOption from "../ui/SelectOption.vue";
export default {
  components: { SelectOption },
  data() {
    return {
      options: [
        {
          id: 1,
          image: "mapMarker/CAKE.png",
          color: "#F7F7F7",
          name: "Title",
        },
        {
          id: 2,
          image: "mapMarker/CAMERA.png",
          color: "#F7F7F7",
          name: "Title",
        },
        {
          id: 3,
          image: "mapMarker/CAR.png",
          color: "#F7F7F7",
          name: "Title",
        },
        {
          id: 4,
          image: "mapMarker/marker4.png",
          color: "#F7F7F7",
          name: "Title",
        },
        {
          id: 5,
          image: "mapMarker/marker5.png",
          color: "#F7F7F7",
          name: "Title",
        },
      ],
    };
  },
  methods: {
    ...mapMutations("momentMap", ["selectedMapMarker"]),
    updateMap(selected) {
      // console.log("selected:", selected);
      this.selectedMapMarker({
        name: selected.name,
        index: selected.id,
      });
    },
  },
};
</script>

<style></style>
