export default {
  async checkPrice(context, payload) {
    const productDetails = context.rootGetters[payload + "/selectedOption"];
    const myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      productName: productDetails.productName,
      frameSize: productDetails.frameSize,
      frameColor: productDetails.frameColor.color,
      printingType: productDetails.printingType,
      giftWrap: productDetails.giftWrapping,
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    await fetch("http://35.164.138.143/calculatePrice/", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        context.commit("setPrice", result.data.price);
        // context.dispatch("checkout/setPrice", result.data.price);
      })
      .catch((error) => console.log("error: ", error));
  },
};
