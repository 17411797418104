<template>
  <div class="px-5 mb-8 relative">
    <div class="font-semibold mb-2">Location:</div>
    <input
      type="text"
      class="form-control"
      placeholder="Enter your location"
      v-model="query"
      @input="search"
    />
    <span v-if="loading" class="absolute right-7 top-12">
      <svg
        class="animate-spin h-5 w-5 text-black"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
      >
        <circle
          class="opacity-25"
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="4"
        ></circle>
        <path
          class="opacity-75"
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </span>
    <ul
      v-if="result"
      class="absolute top-20 rounded-xl bg-white shadow-md z-20 inset-x-5 mt-1"
    >
      <li
        v-for="location in result"
        :key="location.place_id"
        class="p-2 hover:bg-ext-yellow rounded-xl cursor-pointer"
        @click="selectedLocation(location)"
      >
        {{ location.display_name }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  data() {
    return {
      result: [],
      query: "",
      loading: false,
    };
  },
  methods: {
    ...mapMutations("momentMap", ["setCenter"]),
    async search() {
      this.loading = true;
      await fetch(
        `https://nominatim.openstreetmap.org/search?q=${this.query}&limit=5&format=json`
      )
        .then((res) => res.json())
        .then((data) => (this.result = data));
      this.loading = false;
    },
    selectedLocation(location) {
      this.setCenter({ lon: location.lon, lat: location.lat });
      this.result = [];
      this.query = location.display_name;
    },
  },
};
</script>

<style></style>
