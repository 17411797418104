<!-- Please remove this file from your project -->
<template>
  <div class="bg-dark p-3 md:py-3 fixed inset-x-0 top-0 z-50">
    <div class="container max-w-6xl mx-auto flex justify-between items-center">
      <app-logo class="w-24 md:w-auto" logo-color="white" />
      <ul class="flex text-white">
        <li class="md:px-5 px-2"><router-link to="#">Home</router-link></li>
        <li class="md:px-5 px-2" @click="$emit('showProducts')">
          <router-link to="#">Products</router-link>
        </li>
        <li class="md:px-5 px-2"><a href="#ourStory">About us</a></li>
      </ul>
    </div>
  </div>
</template>

<script>
import AppLogo from "./ui/AppLogo.vue";
export default {
  name: "AppHeader",
  components: {
    AppLogo,
  },
};
</script>
