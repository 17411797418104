<template>
  <div class="bg-ext-light-pink min-h-screen">
    <AppHeader />
    <div class="lg:flex md:absolute top-16 bottom-0 w-full">
      <div class="flex-grow self-center">
        <product-preview />
      </div>
      <div class="flex-shrink bg-white py-3 relative" style="width: 512px">
        <ul
          class="grid grid-cols-3 text-center font-semibold shadow-ext-bottom-2 mb-6"
        >
          <li
            class="py-2"
            :class="designSelected ? 'border-b-2 border-black' : 'opacity-50'"
            @click="selectedTab = 'design'"
          >
            <img
              :src="
                formatSelected || detailsSelected
                  ? require('../assets/images/CircleWavyCheckGreen.svg')
                  : require('../assets/images/tabDesign.svg')
              "
              alt=""
              class="mx-auto mb-1"
            />
            Design
          </li>
          <li
            class="py-2"
            :class="detailsSelected ? 'border-b-2 border-black' : 'opacity-50'"
            @click="selectedTab = 'details'"
          >
            <img
              :src="
                formatSelected
                  ? require('../assets/images/CircleWavyCheckGreen.svg')
                  : require('../assets/images/tabDetails.svg')
              "
              alt=""
              class="mx-auto mb-1"
            />
            Details
          </li>
          <li
            class="py-2"
            :class="formatSelected ? 'border-b-2 border-black' : 'opacity-50'"
            @click="selectedTab = 'format'"
          >
            <img
              src="../assets/images/tabFormat.svg"
              alt=""
              class="mx-auto mb-1"
            />
            Format
          </li>
        </ul>
        <div class="absolute top-28 bottom-16 overflow-y-auto inset-x-0">
          <button @click.prevent="createImg">Capture</button>
          {{ selectedOption.title }}
          <div v-if="designSelected">
            <frame-size />
            <location-input />
            <map-styling />
            <map-marker />
          </div>
          <div v-if="detailsSelected">
            <div class="px-5 mb-8">
              <div class="font-semibold mb-2">Title*</div>
              <input
                type="text"
                class="form-control"
                placeholder="Your title"
                v-model="title"
                @change="setImageTitle(title)"
              />
              <small class="text-ext-gray text-xs">Max 25 characters</small>
            </div>
            <div class="px-5 mb-8">
              <div class="font-semibold mb-2">Subtext*</div>
              <input
                type="text"
                class="form-control"
                placeholder="Subtext about your memory"
                v-model="subTitle"
                @change="setImageSubTitle(subTitle)"
              />
              <small class="text-ext-gray text-xs">Max 45 characters</small>
            </div>
          </div>
          <div v-if="formatSelected">
            <frame-color />
            <printing-type />
            <div class="px-5 mb-8">
              <div class="font-semibold mb-2">Gifting message</div>
              <input
                type="text"
                class="form-control"
                placeholder="Write your message"
                v-model="giftingMessage"
                @change="giftingMessage(giftingMessage)"
              />
              <div class="text-ext-gray text-xs my-2">
                Extra charges would apply for gifting
              </div>
              <input
                type="checkbox"
                class="mr-2"
                v-model="giftWrapping"
                @change="setGiftWrapping(giftWrapping)"
              />
              Add gift wrapping
            </div>
          </div>
        </div>
        <div
          class="flex justify-between p-4 absolute bottom-0 inset-x-0 items-center"
        >
          <span class="text-2xl font-semibold"
            ><span v-if="formatSelected">{{ price }}</span></span
          >
          <button class="btn-primary" @click="next">{{ ctaBtnText }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { mapState } from "vuex";
import FrameSize from "../components/productOptions/FrameSize.vue";
import LocationInput from "../components/productOptions/LocationInput.vue";
import AppHeader from "../components/AppHeader.vue";
import MapStyling from "../components/productOptions/MapStyling.vue";
import MapMarker from "../components/productOptions/MapMarker.vue";
import FrameColor from "../components/productOptions/FrameColor.vue";
import PrintingType from "../components/productOptions/PrintingType.vue";
import ProductPreview from "../components/ProductPreview.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";

export default {
  components: {
    AppHeader,
    FrameSize,
    LocationInput,
    MapStyling,
    MapMarker,
    FrameColor,
    PrintingType,
    ProductPreview,
  },
  data() {
    return {
      selectedTab: "design",
      title: "Your title goes here",
      subTitle: "Subtext about your title",
      giftingMessage: "",
      giftWrapping: false,
      ctaBtnText: "Next",
      price: 0,
    };
  },
  computed: {
    ...mapGetters("starMap", ["selectedFrameSize", "selectedOption"]),
    designSelected() {
      return this.selectedTab === "design";
    },
    detailsSelected() {
      return this.selectedTab === "details";
    },
    formatSelected() {
      return this.selectedTab === "format";
    },
    // price() {
    //   return (
    //     this.selectedOption.basePrice +
    //     this.selectedOption.frameSize.price +
    //     this.selectedOption.printingType.price
    //   );
    // },
  },
  methods: {
    ...mapMutations([
      "setImageTitle",
      "setImageSubTitle",
      "setGiftingMessage",
      "setGiftWrapping",
    ]),
    ...mapActions(["createImg"]),
    next() {
      if (this.designSelected) {
        this.selectedTab = "details";
      } else if (this.detailsSelected) {
        this.selectedTab = "format";
        this.ctaBtnText = "Checkout";
      } else if (this.formatSelected) {
        // this.ctaBtnText = "Checkout";
      }
    },
  },
};
</script>

<style></style>
