<template>
  <app-header />
  <div class="container max-w-6xl mx-auto">
    <div class="lg:grid grid-cols-12 gap-8">
      <div class="col-span-8 pt-20 px-3">
        <base-text type="h4">Checkout Information</base-text>
        <base-card>
          <template #icon><img src="@/assets/images/mail.svg" /></template>
          <template #title>Contact Information</template>
          <template #body>
            Email:
            <input
              type="email"
              placeholder="Enter email"
              class="form-control my-2"
              :class="{ error: v$.form.email.$errors.length }"
              v-model="v$.form.email.$model"
            />
            <div v-for="(error, index) of v$.form.email.$errors" :key="index">
              <div class="errorMsg">{{ error.$message }}</div>
            </div>
            <input
              type="checkbox"
              id="nl"
              class="mr-2"
              v-model="form.send_news_and_Offers"
            />
            <label for="nl">Email me with news and offers</label>
          </template>
        </base-card>

        <base-card>
          <template #icon><img src="@/assets/images/marker.svg" /></template>
          <template #title>Shipping address</template>
          <template #body>
            <div class="md:grid grid-cols-8 gap-6">
              <div class="col-span-8">
                Country/Region:
                <select class="form-control my-2">
                  <option>India</option>
                </select>
                {{ form.billing.firstName }}
              </div>
              <div class="col-span-4">
                First name
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="Enter your first name"
                  :class="{ error: v$.form.shipping.firstName.$errors.length }"
                  v-model="v$.form.shipping.firstName.$model"
                />
                <div
                  v-for="(error, index) of v$.form.shipping.firstName.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-span-4">
                Last name
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="Enter your last name"
                  :class="{ error: v$.form.shipping.lastName.$errors.length }"
                  v-model="v$.form.shipping.lastName.$model"
                />
                <div
                  v-for="(error, index) of v$.form.shipping.lastName.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-span-8">
                Address:
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="Enter your location"
                  :class="{ error: v$.form.shipping.address.$errors.length }"
                  v-model="v$.form.shipping.address.$model"
                />
                <div
                  v-for="(error, index) of v$.form.shipping.address.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-span-4">
                Apartment, Suite, etc. (Optional)
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="House no, apartment etc."
                />
              </div>
              <div class="col-span-4">
                PIN Code
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="PIN Code"
                  :class="{ error: v$.form.shipping.pin.$errors.length }"
                  v-model="v$.form.shipping.pin.$model"
                />
                <div
                  v-for="(error, index) of v$.form.shipping.pin.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-span-4">
                City
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="Enter City"
                  :class="{ error: v$.form.shipping.city.$errors.length }"
                  v-model="v$.form.shipping.city.$model"
                />
                <div
                  v-for="(error, index) of v$.form.shipping.city.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-span-4">
                State
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="Enter State"
                  :class="{ error: v$.form.shipping.state.$errors.length }"
                  v-model="v$.form.shipping.state.$model"
                />
                <div
                  v-for="(error, index) of v$.form.shipping.state.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-span-8">
                Phone no.
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="8174XXXXXX"
                  :class="{ error: v$.form.shipping.phone.$errors.length }"
                  v-model="v$.form.shipping.phone.$model"
                />
                <div
                  v-for="(error, index) of v$.form.shipping.phone.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
                <input
                  type="checkbox"
                  id="Biilling"
                  class="mr-2"
                  v-model="sameBillingAddress"
                />
                <label for="Biilling">Use same as Biilling address</label>
              </div>
            </div>
          </template>
        </base-card>

        <base-card v-if="!sameBillingAddress">
          <template #icon><img src="@/assets/images/marker.svg" /></template>
          <template #title>Billing address</template>
          <template #body>
            <div class="md:grid grid-cols-8 gap-6">
              <div class="col-span-8">
                Country/Region:
                <select class="form-control my-2">
                  <option>India</option>
                </select>
              </div>
              <div class="col-span-4">
                First name
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="Enter your first name"
                  :class="{ error: v$.form.billing.firstName.$errors.length }"
                  v-model="v$.form.billing.firstName.$model"
                />
                <div
                  v-for="(error, index) of v$.form.billing.firstName.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-span-4">
                Last name
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="Enter your last name"
                  :class="{ error: v$.form.billing.lastName.$errors.length }"
                  v-model="v$.form.billing.lastName.$model"
                />
                <div
                  v-for="(error, index) of v$.form.billing.lastName.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-span-8">
                Address:
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="Enter your location"
                  :class="{ error: v$.form.billing.address.$errors.length }"
                  v-model="v$.form.billing.address.$model"
                />
                <div
                  v-for="(error, index) of v$.form.billing.address.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-span-4">
                Apartment, Suite, etc. (Optional)
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="House no, apartment etc."
                />
              </div>
              <div class="col-span-4">
                PIN Code
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="PIN Code"
                  :class="{ error: v$.form.billing.pin.$errors.length }"
                  v-model="v$.form.billing.pin.$model"
                />
                <div
                  v-for="(error, index) of v$.form.billing.pin.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-span-4">
                City
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="Enter City"
                  :class="{ error: v$.form.billing.city.$errors.length }"
                  v-model="v$.form.billing.city.$model"
                />
                <div
                  v-for="(error, index) of v$.form.billing.city.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-span-4">
                State
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="Enter State"
                  :class="{ error: v$.form.billing.state.$errors.length }"
                  v-model="v$.form.billing.state.$model"
                />
                <div
                  v-for="(error, index) of v$.form.billing.state.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>
              <div class="col-span-8">
                Phone no.
                <input
                  type="text"
                  class="form-control my-2"
                  placeholder="8174XXXXXX"
                  :class="{ error: v$.form.billing.phone.$errors.length }"
                  v-model="v$.form.billing.phone.$model"
                />
                <div
                  v-for="(error, index) of v$.form.billing.phone.$errors"
                  :key="index"
                >
                  <div class="errorMsg">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </template>
        </base-card>
      </div>
      <div class="col-span-4 relative pt-16">
        <div class="absolute w-full">
          <div class="p-6 bg-ext-light-pink fixed">
            <p class="uppercase">Your Order</p>
            <hr class="my-7" />
            <div class="grid grid-cols-4">
              <div></div>
              <div class="col-span-3">
                <base-text type="h5" add-class="uppercase">
                  {{ getProductDetails.productName }}
                </base-text>
                <div class="text-ext-gray -mt-2 mb-2">Quantity: 1</div>
                <button class="underline">Edit</button>
              </div>
            </div>
            <hr class="my-7" />
            <div class="grid grid-cols-4 gap-1">
              <input
                type="text"
                class="form-control bg-white col-span-3"
                placeholder="Discount Code"
              />
              <button class="btn-primary rounded-lg">Apply</button>
            </div>
            <hr class="my-7" />
            <div class="flex justify-between mb-4">
              <span class="text-ext-gray">Subtotal:</span>
              <strong>₹ {{ getPrice }}</strong>
            </div>
            <div class="flex justify-between mb-4">
              <span class="text-ext-gray">Shipping:</span>
              <strong>Free</strong>
            </div>
            <hr class="my-7" />
            <div class="flex justify-between mb-4">
              <div>
                <strong>Total:</strong>
                <div class="text-ext-gray text-sm">Inclusive of all taxes</div>
              </div>
              <strong class="text-3xl">₹ {{ getPrice }}</strong>
            </div>
            <base-button class="w-full" @click.prevent="continuePayment"
              >Continue to payment</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import BaseText from "@/components/ui/BaseText.vue";
import BaseCard from "@/components/ui/BaseCard.vue";
import BaseButton from "@/components/ui/BaseButton.vue";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  numeric,
  minLength,
  maxLength,
} from "@vuelidate/validators";
import { mapGetters, mapMutations } from "vuex";

export function validName(name) {
  let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
  if (validNamePattern.test(name)) {
    return true;
  }
  return false;
}

export default {
  components: { AppHeader, BaseText, BaseCard, BaseButton },
  data() {
    return {
      sameBillingAddress: false,
      form: {
        email: "",
        send_news_and_Offers: false,
        shipping: {
          firstName: "",
          lastName: "",
          address: "",
          apartment: "",
          pin: "",
          city: "",
          state: "",
          phone: "",
        },
        billing: {
          firstName: "",
          lastName: "",
          address: "",
          apartment: "",
          pin: "",
          city: "",
          state: "",
          phone: "",
        },
      },
    };
  },
  validations() {
    return {
      form: {
        email: {
          required,
          email,
        },
        shipping: {
          firstName: {
            required,
            name_validation: {
              $validator: validName,
              $message:
                "Invalid Name. Valid name only contain letters, dashes (-) and spaces",
            },
          },
          lastName: {
            required,
            name_validation: {
              $validator: validName,
              $message:
                "Invalid Name. Valid name only contain letters, dashes (-) and spaces",
            },
          },
          phone: {
            required,
            numeric,
            min: minLength(10),
            max: maxLength(10),
          },
          address: {
            required,
          },
          pin: {
            numeric,
            required,
          },
          city: {
            required,
          },
          state: {
            required,
          },
        },
        billing: {
          firstName: {
            required,
            name_validation: {
              $validator: validName,
              $message:
                "Invalid Name. Valid name only contain letters, dashes (-) and spaces",
            },
          },
          lastName: {
            required,
            name_validation: {
              $validator: validName,
              $message:
                "Invalid Name. Valid name only contain letters, dashes (-) and spaces",
            },
          },
          phone: {
            required,
            numeric,
            min: minLength(10),
            max: maxLength(10),
          },
          address: {
            required,
          },
          pin: {
            numeric,
            required,
          },
          city: {
            required,
          },
          state: {
            required,
          },
        },
      },
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  computed: {
    ...mapGetters("checkout", [
      "getPrice",
      "getProductDetails",
      "getUserDetails",
    ]),
  },
  methods: {
    ...mapMutations("checkout", [
      "setShippingDetails",
      "setBillingDetails",
      "setUserEmail",
      "setSend_news_and_Offers",
    ]),
    async continuePayment() {
      const result = await this.v$.$validate();
      if (!result) {
        console.log(this.form);
        return;
      } else {
        this.setShippingDetails(this.form.shipping);
        this.setBillingDetails(this.form.billing);
        this.setUserEmail(this.form.email);
        this.setSend_news_and_Offers(this.form.send_news_and_Offers);
        this.$router.push("/proceed-to-payment");
      }
    },
  },
  watch: {
    sameBillingAddress(newVal) {
      if (newVal) {
        this.form.billing = this.form.shipping;
      } else {
        this.form.billing = {
          firstName: "",
          lastName: "",
          address: "",
          apartment: "",
          pin: "",
          city: "",
          state: "",
          phone: "",
        };
      }
    },
  },
};
</script>

<style></style>
