<template>
  <app-header />
  <div class="container max-w-6xl mx-auto">
    <div class="lg:grid grid-cols-12 gap-8">
      <div class="col-span-8 pt-20 px-3">
        <base-text type="h4">Payment Information</base-text>

        <div class="border border-ext-light-gray p-3 rounded-md">
          <div class="pb-3 mb-3 border-b border-ext-light-gray">
            <div class="grid grid-cols-12">
              <div class="col-span-2 text-ext-gray">Contact:</div>
              <div class="col-span-9">{{ getUserDetails.email }}</div>
              <div class="col-span-1 text-right"></div>
            </div>
          </div>
          <div class="grid grid-cols-12">
            <div class="col-span-2 text-ext-gray">Ship to:</div>
            <div class="col-span-9">
              {{ getUserDetails.shippingDetails.address }},
              {{
                getUserDetails.shippingDetails.apartment
                  ? getUserDetails.shippingDetails.apartment + ","
                  : " "
              }}
              {{ getUserDetails.shippingDetails.pin }},
              {{ getUserDetails.shippingDetails.city }},
              {{ getUserDetails.shippingDetails.state }}
            </div>
            <div class="col-span-1 text-right"></div>
          </div>
        </div>
      </div>
      <div class="col-span-4 relative pt-16">
        <div class="absolute w-full">
          <div class="p-6 bg-ext-light-pink fixed">
            <p class="uppercase">Your Order</p>
            <hr class="my-7" />
            <div class="grid grid-cols-4">
              <div></div>
              <div class="col-span-3">
                <base-text type="h5" add-class="uppercase">
                  {{ getProductDetails.productName }}
                </base-text>
                <div class="text-ext-gray -mt-2 mb-2">Quantity: 1</div>
                <button class="underline">Edit</button>
              </div>
            </div>
            <hr class="my-7" />
            <div class="flex justify-between mb-4">
              <span class="text-ext-gray">Subtotal:</span>
              <strong>₹ {{ getPrice }}</strong>
            </div>
            <div class="flex justify-between mb-4">
              <span class="text-ext-gray">Shipping:</span>
              <strong>Free</strong>
            </div>
            <hr class="my-7" />
            <div class="flex justify-between mb-4">
              <div>
                <strong>Total:</strong>
                <div class="text-ext-gray text-sm">Inclusive of all taxes</div>
              </div>
              <strong class="text-3xl">₹ {{ getPrice }}</strong>
            </div>
            <base-button class="w-full" @click.prevent="createOrder"
              >Continue to payment</base-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader.vue";
import BaseText from "@/components/ui/BaseText.vue";
import BaseButton from "@/components/ui/BaseButton.vue";

import { mapGetters, mapMutations } from "vuex";

export function validName(name) {
  let validNamePattern = new RegExp("^[a-zA-Z]+(?:[-'\\s][a-zA-Z]+)*$");
  if (validNamePattern.test(name)) {
    return true;
  }
  return false;
}

export default {
  components: { AppHeader, BaseText, BaseButton },
  data() {
    return {
      sameBillingAddress: false,
      form: {
        email: "",
        send_news_and_Offers: false,
      },
    };
  },
  computed: {
    ...mapGetters("checkout", [
      "getPrice",
      "getProductDetails",
      "getUserDetails",
    ]),
  },
  methods: {
    ...mapMutations("checkout", [
      "setShippingDetails",
      "setBillingDetails",
      "setUserEmail",
      "setSend_news_and_Offers",
    ]),
    async createOrder() {
      const raw = JSON.stringify({
        send_news_and_Offers: this.getUserDetails.send_news_and_Offers,
        total_amount: this.getPrice,
        quantity: 1,
        email: this.getUserDetails.email,
        product: this.getProductDetails,
        shipping_address: this.getUserDetails.shippingDetails,
        billing_address: this.getUserDetails.billingDetails,
      });
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const response = await fetch(
        "http://35.164.138.143/createOrder/",
        requestOptions
      );

      if (response.status == 200) {
        const myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("x-client-id", "1524403344731507fa025f45b0044251");
        myHeaders.append(
          "x-client-secret",
          "baa03fdce4e4a520a2d9b9d9faca1d99dfa6c139"
        );
        myHeaders.append("x-api-version", "2022-01-01");
        myHeaders.append("Content-Type", "application/json");

        const uid = function () {
          return Date.now().toString(36) + Math.random().toString(36).substr(2);
        };

        let raw = JSON.stringify({
          customer_details: {
            customer_id: "34535443FDGD",
            customer_email: "test@test.com",
            customer_phone: "1234567890",
          },
          order_id: uid().toString(),
          order_amount: 10,
          order_currency: "INR",
        });

        const requestOptions = {
          method: "POST",
          // mode: "no-cors",
          headers: myHeaders,
          body: raw,
          redirect: "follow",
        };

        fetch("https://sandbox.cashfree.com/pg/orders", requestOptions)
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.log("error", error));
      }
    },
  },
};
</script>

<style></style>
